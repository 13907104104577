import React from 'react'
import PropTypes from 'prop-types'
import { getCookie, setCookie } from '../../util/cookie'
import geoipDetect from '../../shared/geoipDetect'
import './styles.scss'

import { l10n } from '../../shared/constants'
const { langIdentifier } = l10n

const switchNoticeBeforeLink = {
  en: 'Please click the button to ',
  de: 'Bitte klicken Sie auf den Button, um unsere ',
  nl: 'Klik op de knop om onze inhoud ',
  fr: 'Veuillez cliquer sur le bouton pour ',
  se: 'Klicka på knappen för att se vårt ',
}

const switchNoticeLink = {
  en: 'view our content in English',
  de: 'Website auf Deutsch anzeigen',
  nl: 'in het Nederlands te bekijken',
  fr: 'afficher notre contenu en français',
  se: 'innehåll på svenska',
}

const switchNoticeAfterLink = {
  en: '.',
  de: ' zu lassen.',
  nl: '.',
  fr: '.',
  se: '.',
}

const switchButton = {
  en: 'Continue',
  de: 'Weiter',
  nl: 'Verdergaan',
  fr: 'Continuer',
  se: 'Fortsätt',
}

class GeoIpDetect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      switchTo: false,
      closeDisclaimer: getCookie('geoDisclaimerClosed')
        ? getCookie('geoDisclaimerClosed')
        : false,
    }

    this.closeDisclaimer = this.closeDisclaimer.bind(this)
  }

  componentDidMount() {
    const self = this
    geoipDetect.get_info().then(function (record) {
      const error =
        (record.extra && record.extra.error) ||
        (record.data.extra && record.data.extra.error)
      const iso_code =
        record.data && record.data.country && record.data.country['iso_code']

      if (!error && iso_code) {
        if (langIdentifier === 'en') {
          if (['DE', 'AT', 'CH'].includes(iso_code)) {
            self.setState({ switchTo: 'de' })
          } else if (['FR'].includes(iso_code)) {
            self.setState({ switchTo: 'fr' })
          } else if (['NL'].includes(iso_code)) {
            self.setState({ switchTo: 'nl' })
          } else if (['SE'].includes(iso_code)) {
            self.setState({ switchTo: 'se' })
          }
        } else if (langIdentifier === 'de') {
          if (['FR'].includes(iso_code)) {
            self.setState({ switchTo: 'fr' })
          } else if (['NL'].includes(iso_code)) {
            self.setState({ switchTo: 'nl' })
          } else if (['SE'].includes(iso_code)) {
            self.setState({ switchTo: 'se' })
          } else if (!['DE', 'AT', 'CH'].includes(iso_code)) {
            self.setState({ switchTo: 'en' })
          }
        } else if (langIdentifier === 'fr') {
          if (['DE'].includes(iso_code)) {
            self.setState({ switchTo: 'de' })
          } else if (['NL'].includes(iso_code)) {
            self.setState({ switchTo: 'nl' })
          } else if (['SE'].includes(iso_code)) {
            self.setState({ switchTo: 'se' })
          } else if (!['FR'].includes(iso_code)) {
            self.setState({ switchTo: 'en' })
          }
        } else if (langIdentifier === 'nl') {
          if (['DE'].includes(iso_code)) {
            self.setState({ switchTo: 'de' })
          } else if (['FR'].includes(iso_code)) {
            self.setState({ switchTo: 'fr' })
          } else if (['SE'].includes(iso_code)) {
            self.setState({ switchTo: 'se' })
          } else if (!['NL'].includes(iso_code)) {
            self.setState({ switchTo: 'en' })
          }
        } else if (langIdentifier === 'sv') {
          if (['DE'].includes(iso_code)) {
            self.setState({ switchTo: 'de' })
          } else if (['FR'].includes(iso_code)) {
            self.setState({ switchTo: 'fr' })
          } else if (['NL'].includes(iso_code)) {
            self.setState({ switchTo: 'nl' })
          } else if (!['SE'].includes(iso_code)) {
            self.setState({ switchTo: 'en' })
          }
        }
      }
    })
  }

  componentDidUpdate() {
    //
  }

  closeDisclaimer() {
    setCookie('geoDisclaimerClosed', 1)
    this.setState({ closeDisclaimer: true })
  }

  render() {
    const { wpml_active_languages } = this.props
    const { switchTo, closeDisclaimer } = this.state

    const renderThis =
      switchTo && langIdentifier !== switchTo && !closeDisclaimer

    const wpmlLink =
      wpml_active_languages[switchTo] && wpml_active_languages[switchTo].url

    const fallbackLink =
      document.location.origin +
      (switchTo !== langIdentifier ? `/${switchTo}` : '')

    const switchLink = wpmlLink || fallbackLink

    return (
      renderThis && (
        <div className={`dhsv_geoip-detect`}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className={`dhsv_geoip-detect_wrapper`}>
                  <p>
                    {switchNoticeBeforeLink[switchTo]}
                    <a
                      onClick={e => {
                        setCookie('geoDisclaimerClosed', 1)
                        document.location = e.target.href
                      }}
                      href={switchLink}
                    >
                      {switchNoticeLink[switchTo]}
                    </a>
                    {switchNoticeAfterLink[switchTo]}
                  </p>
                  <a
                    onClick={e => {
                      setCookie('geoDisclaimerClosed', 1)
                      document.location = e.target.href
                    }}
                    href={switchLink}
                    className="btn"
                  >
                    {switchButton[switchTo]}
                  </a>
                  <span
                    onClick={this.closeDisclaimer}
                    className="material-icons"
                  >
                    close
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }
}

GeoIpDetect.propTypes = {
  wpml_active_languages: PropTypes.objectOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default GeoIpDetect
